<template>
    <div class="wrap">
        <div class="space_pc">
            <section class="main space4_4 space4_5">
                <!-- 로딩 완료 -->
                <div class="main_box">
                    <h3>
                        큐레이션 변경 준비가 완료되었습니다.<br>
                        팝업의 '열기' 버튼을 누르시면<br>
                        변경된 큐레이션으로 즉시 재생됩니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll" >
                            <div><img src="/media/img/space_4_check.svg" alt=""></div>
                            <img src="/media/img/space_loding_img2.png" alt="">
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 로딩 완료 -->
        <div class="space_mo h_100">
            <section class="main space4_4 space4_5">
                <div class="main_box">
                    <h3>
                        큐레이션 변경 준비가 완료되었습니다. 분위기의<br>
                        팝업의 '열기' 버튼을 누르시면 변경된 큐레이션으로 즉시 재생됩니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt=""></div>
                            <img src="/media/img/space_loding_img2.png" alt="">
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "InstallablePlayerUpdateCuration",
  mounted () {
    window.location.href = 'aplayz://changedCuration';
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
